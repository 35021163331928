import { Calendar } from "react-date-range";
import moment from "moment";
import React from "react";
import { getDatesInRange, getDatesAndSlotsByDate, getUniqueSlots } from "utils";
import { useSelector } from "react-redux";

const DateTimePicker = ({
  date,
  handleSelect,
  selectedSlot,
  setSelectedSlot,
  setIsSlotConfirm,
}) => {
  const { slotsAvailable, event } = useSelector((state) => state.bookSlot);

  const { dates, slotsByDate } = getDatesAndSlotsByDate(slotsAvailable);
  const selectedDate = date ? moment(date).format("YYYY-MM-DD") : "";
  const uniqueSlotsPerDate = getUniqueSlots(slotsByDate, selectedDate);

  // start date to show in calendar
  const startDate =
    new Date(event.start_date) > new Date()
      ? new Date(event.start_date)
      : new Date();

  // end date to show in calendar
  const endDate = new Date(event.end_date);

  // all dates from start to end date
  const allDates = getDatesInRange(startDate, endDate);

  let disabledDates = [];
  allDates.forEach((date) => {
    if (!dates.includes(date)) {
      disabledDates.push(new Date(date));
    }
  });

  return dates.length > 0 ? (
    <>
      <div className="py-4 sm:p-4">
        <h4 className="font-medium text-lg p-2 sm:p-0">Select a Date & Time</h4>
        <Calendar
          className="rounded-lg border calendar"
          minDate={startDate}
          maxDate={endDate}
          disabledDates={disabledDates}
          shownDate={startDate}
          date={date}
          color="#8B2F8D"
          onChange={handleSelect}
        />
      </div>
      {date && (
        <div className="grow p-4">
          <h4 className="font-medium pb-2 py-2 sm:pt-10 text-lg">
            {moment(date).format("MMMM Do YYYY")}
          </h4>
          <div className="pr-2 min-w-[15.625rem] max-w-[18.75rem] sm:max-h-[21.125rem] flex flex-col gap-2 sm:overflow-y-scroll">
            {uniqueSlotsPerDate.length > 0 ? (
              uniqueSlotsPerDate.map((slot) => {
                if (+slot.start_time > new Date().getTime()) {
                  return selectedSlot?.SESSION_START_TIME ===
                    slot.SESSION_START_TIME ? (
                    <div key={slot.id} className="flex gap-2">
                      <span className="bg-header text-center grow text-white font-semibold p-1 rounded">
                        {slot.SESSION_START_TIME}
                      </span>
                      <button
                        onClick={() => setIsSlotConfirm(true)}
                        className="bg-secondary-ctc text-white font-semibold grow rounded p-1"
                      >
                        Confirm
                      </button>
                    </div>
                  ) : (
                    <span
                      key={slot.id}
                      onClick={() => setSelectedSlot(slot)}
                      className="border-2 cursor-pointer hover:transition-all duration-700 ease-in-out hover:bg-primaryFaded border-header rounded text-center py-1 font-semibold"
                    >
                      {slot.SESSION_START_TIME}
                    </span>
                  );
                } else {
                  return <></>;
                }
              })
            ) : (
              <div className="text-center py-2 text-gray-400">
                No slot available
              </div>
            )}
          </div>
        </div>
      )}
    </>
  ) : (
    <p className="grow py-2 px-4 flex flex-col justify-center items-center font-semibold text-gray-400">
      <span>Sorry,</span>
      No slots available
    </p>
  );
};

export { DateTimePicker };
