import React from "react";
import MeeraqLogo from "../../assets/meeraq_logo_colored.png";

const Header = () => {
  return (
    <header className="px-6 py-2 flex flex-wrap items-center shadow-md shadow-gray-300 justify-between  ">
      <img className="w-40 mt-1" src={MeeraqLogo} alt="Meeraq Logo" />
    </header>
  );
};

export { Header };
