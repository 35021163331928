import React from "react";

export const WelcomePage = () => {
  return (
    <div className="h-[100%]">
      <div class="bubbles">
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
      </div>
      <section
        className={`sm:shadow-lg relative z-1 h-full sm:mt-8 sm:border pb-4 mx-auto rounded-lg max-w-[800px]`}
      >
        <div className="text-center py-2 border-b flex flex-col items-center justify-center">
          <p className="text-3xl py-4">Welcome to MEERAQ!</p>
        </div>
        <div className="border-t py-4">
          <p className="py-2 text-xl text-center">
            Grow your people, Grow your company.{" "}
          </p>
          <p className="py-2 text-center">
            We help organizations transform their people and culture through
            customized behavioural change interventions delivered at scale{" "}
          </p>
        </div>
        <div className="border-t text-center pt-4">
          <a href="https://www.meeraq.com" target="_blank" rel="noreferrer" className="">
            Explore Meeraq.
          </a>
        </div>
      </section>
    </div>
  );
};
