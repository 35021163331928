import { format } from "date-fns";
export function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate);
  const endDateString = format(endDate, "yyyy-MM-dd");

  const dates = [];

  while (format(date, "yyyy-MM-dd") <= endDateString) {
    dates.push(format(new Date(date), "yyyy-MM-dd"));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
