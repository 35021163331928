import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { bookSlot } from "redux-management/slices/book-slot/bookSlotService";
import { validateEmail } from "utils/validateEmail";
import { validatePhone } from "utils/validatePhone";
import { validateName } from "utils/validateName";
import { toast } from "react-toastify";

const initialFormValidationMessage = {
  name: null,
  email: null,
  phone: null,
};

const numberCharactersAllowed = "1234567890 ";
const nameRegex = /^[a-zA-Z .]*$/;

const UserForm = ({
  setIsSlotConfirm,
  selectedSlot,
  userDetails,
  setUserDetails,
}) => {
  const [formValidationMessage, setFormValidationMessage] = useState(
    initialFormValidationMessage
  );

  const [activateToastId, setActiveToastId] = useState();

  const dispatch = useDispatch();
  const { eventId } = useParams();

  const { name, email, phone, organization } = userDetails;

  const inputHandler = (e, type) => {
    if (type === "name") {
      setUserDetails((prev) => ({ ...prev, name: e.target.value }));
      setFormValidationMessage((prev) => ({
        ...prev,
        name: null,
      }));
    } else if (type === "email") {
      setFormValidationMessage((prev) => ({ ...prev, email: null }));
      setUserDetails((prev) => ({ ...prev, email: e.target.value }));
    } else if (type === "phone") {
      setFormValidationMessage((prev) => ({ ...prev, phone: null }));
      if (e.target.value > 9999999999) {
        if (!toast.isActive(activateToastId)) {
          const toastId = toast.warning("Phone no. cannot exceed 10 digits.");
          setActiveToastId(toastId);
        }
      } else {
        if (
          numberCharactersAllowed.includes(e.nativeEvent?.data) ||
          e.nativeEvent?.inputType === "deleteContentBackward" ||
          Boolean(Number(e.target.value))
        ) {
          setUserDetails((prev) => ({ ...prev, phone: e.target.value }));
          setFormValidationMessage((prev) => ({
            ...prev,
            phone: null,
          }));
        }
      }
    } else if (type === "organization") {
      setUserDetails((prev) => ({ ...prev, organization: e.target.value }));
    }
  };

  const blurHandler = (e, type) => {
    if (type === "name") {
      if (userDetails.name === "") {
        setFormValidationMessage((prev) => ({
          ...prev,
          name: "Name can't be empty",
        }));
      } else if (userDetails.name.length < 3) {
        setFormValidationMessage((prev) => ({
          ...prev,
          name: "Name should be minimum 3 characters long.",
        }));
      } else if (!nameRegex.test(userDetails.name)) {
        setFormValidationMessage((prev) => ({
          ...prev,
          name: "Only alphabets and . are allowed",
        }));
      }
    }

    if (type === "email") {
      if (userDetails.email.trim() === "") {
        setFormValidationMessage((prev) => ({
          ...prev,
          email: "E-mail can't be empty",
        }));
      } else if (!validateEmail(userDetails.email.trim())) {
        setFormValidationMessage((prev) => ({
          ...prev,
          email: "E-mail address is not valid",
        }));
      }
    }

    if (type === "phone") {
      if (e.target.value === "") {
        setFormValidationMessage((prev) => ({
          ...prev,
          phone: "Phone number can't be empty",
        }));
      } else if (userDetails.phone < 1000000000) {
        setFormValidationMessage((prev) => ({
          ...prev,
          phone: "Phone number can't be less than 10 digits",
        }));
      }
    }
  };

  const scheduleSessionHandler = (e) => {
    e.preventDefault();
    const {
      name: nameErr,
      phone: phoneErr,
      email: emailErr,
    } = formValidationMessage;
    if (!nameErr && !phoneErr && !emailErr) {
      if (
        validatePhone(phone).isCorrect &&
        validateEmail(email) &&
        validateName(name)
      ) {
        dispatch(
          bookSlot({
            user: userDetails,
            eventId: eventId,
            slotId: selectedSlot.id,
          })
        );
      }
    }
  };

  return (
    <form
      onSubmit={scheduleSessionHandler}
      className="py-2 flex-col flex gap-2"
    >
      <div className="flex flex-col gap-1">
        <label className="relative font-semibold" htmlFor="name">
          Name <span className="text-red-500">*</span>
        </label>
        <input
          value={name}
          onChange={(e) => inputHandler(e, "name")}
          onBlur={(e) => blurHandler(e, "name")}
          className="rounded outline-0 border border-slate-300 py-2 px-2 required"
          type="text"
          id="name"
          autoComplete="off"
        />
        {formValidationMessage.name && (
          <p className="text-red-500 px-1">{formValidationMessage.name}</p>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <label className="relative font-semibold" htmlFor="email">
          E-mail <span className="text-red-500">*</span>
        </label>
        <input
          value={email}
          onChange={(e) => inputHandler(e, "email")}
          onBlur={(e) => blurHandler(e, "email")}
          className="rounded outline-0 border border-slate-300 py-2 px-2 required"
          type="email"
          id="email"
        />
        {formValidationMessage.email && (
          <p className="text-red-500 px-1">{formValidationMessage.email}</p>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <label className="relative font-semibold" htmlFor="phone">
          Phone no. <span className="text-red-500">*</span>
        </label>
        <input
          value={phone}
          inputmode="numeric"
          min={0}
          onChange={(e) => inputHandler(e, "phone")}
          onBlur={(e) => blurHandler(e, "phone")}
          onKeyDown={(e) =>
            (e.key === "e" ||
              e.key === "." ||
              e.key === "+" ||
              e.key === "-") &&
            e.preventDefault()
          }
          className="outline-0 border border-slate-300 rounded py-2 px-2 required"
          type="number"
          id="phone"
        />
        {formValidationMessage.phone && (
          <p className="text-red-500 px-1">{formValidationMessage.phone}</p>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <label className="relative font-semibold" htmlFor="organization">
          Organization <span className="text-red-500"></span>
        </label>
        <input
          value={organization}
          onChange={(e) => inputHandler(e, "organization")}
          className="outline-0 border border-slate-300 rounded py-2 px-2 required"
          type="text"
          id="organization"
        />
      </div>

      <div className="pt-2 flex gap-4">
        <Button
          type="secondary"
          htmlType="submit"
          disabled={name && phone && email ? false : true}
          className="rounded font-semibold bg-primary-ctc focus:bg-primary-ctc hover:bg-primary-ctc-hover hover:text-white border-0 focus:text-white text-white"
        >
          Schedule Session
        </Button>
        <Button
          onClick={() => setIsSlotConfirm(false)}
          type="danger"
          className="rounded font-semibold text-red-500"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export { UserForm };
