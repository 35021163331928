export const validatePhone = (phone) => {
  if (isNaN(phone)) {
    return {
      isCorrect: false,
      message: "Phone is not a valid number",
    };
  } else if (phone < 1000000000) {
    return { isCorrect: false, message: "Phone should be minimum 10 digits." };
  } else if (phone > 9999999999) {
    return { isCorrect: false, message: "Phone can't excced 10 digits " };
  } else {
    return { isCorrect: true };
  }
};
