import { configureStore } from "@reduxjs/toolkit";
// import slotBookingReducer from "./slices/slot-booking/slotBookingSlice";
// import authReducer from "./slices/auth/authSlice";
import bookSlotReducer from "./slices/book-slot/bookSlotSlice";

export const store = configureStore({
  reducer: {
    // slotBooking: slotBookingReducer,
    // auth:  authReducer,
    bookSlot: bookSlotReducer,
  },
});
