import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getAvailableSlotsFromEvent = createAsyncThunk(
  "get-book-slot",
  async (eventId, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}get-slots-by-event-id/${eventId}/`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bookSlot = createAsyncThunk(
  "book-slot",
  async ({ slotId, user, eventId }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}confirm-slot-by-learner/${slotId}/`,
        {
          name: user.name,
          phone_no: user.phone,
          organisation: user.organization,
          email: user.email,
          event: eventId,
          warning: true,
        }
      );
      return res.data;
    } catch (err) {
      if (err.response.status === 409) {
        toast.error("You have already booked a slot for this event");
      }
      if (err.response.status === 405) {
        toast.error("Incorrect email: Please enter the registered email id.");
      }
      return rejectWithValue(err);
    }
  }
);
