import { createSlice } from "@reduxjs/toolkit";
import { getAvailableSlotsFromEvent, bookSlot } from "./bookSlotService";

const initialState = {
  slotsAvailable: [],
  event: null,
  fetchingSlots: "loading",
  bookingSlot: "idle",
};

export const bookSlotSlice = createSlice({
  name: "book-slot",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAvailableSlotsFromEvent.pending, (state, action) => {
      state.fetchingSlots = "loading";
      state.slotsAvailable = [];
      state.bookingSlot = "idle";
    });
    builder.addCase(getAvailableSlotsFromEvent.fulfilled, (state, action) => {
      state.fetchingSlots = "success";
      state.slotsAvailable = action.payload.slots;
      state.event = action.payload.event;
    });
    builder.addCase(getAvailableSlotsFromEvent.rejected, (state, action) => {
      state.fetchingSlots = "failed";
      state.slotsAvailable = [];
      state.event = null;
    });
    builder.addCase(bookSlot.pending, (state, action) => {
      state.bookingSlot = "loading";
    });
    builder.addCase(bookSlot.fulfilled, (state, action) => {
      state.bookingSlot = "success";
    });
    builder.addCase(bookSlot.rejected, (state, action) => {
      if (action.payload.response.status === 409) {
        state.bookingSlot = "idle";
      } else if (action.payload.response.status === 405) {
        state.bookingSlot = "idle";
      } else {
        state.bookingSlot = "failed";
      }
    });
  },
});

export default bookSlotSlice.reducer;
