export const getDatesAndSlotsByDate = (slots) => {
  const slotsByDate = {};
  slots.forEach((slot) => {
    if (slotsByDate[slot.date]) {
      slotsByDate[slot.date] = [
        ...slotsByDate[slot.date],
        {
          ...slot,
        },
      ];
    } else {
      slotsByDate[slot.date] = [
        {
          ...slot,
        },
      ];
    }
  });
  const dates = Object.keys(slotsByDate);

  dates.sort();

  return { dates, slotsByDate };
};
