import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLearner = createAsyncThunk(
  "slotBooking/getLearner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_LEARNER}`,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(`Error from getLearner: ${error.message}`);
    }
  }
);

export const getAvailableSlots = createAsyncThunk(
  "slotBooking/getAvailableSlots",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_AVAILABLE_SLOTS}`,
        {
          learnerId: data.learnerId,
          batchId: data.batchId,
          weekId: data.weekId,
        },
        {
          headers: {
            Authorization: `Token ${data.token}`,
          },
        }
      );

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pickLearnerSlot = createAsyncThunk(
  "slotBooking/pickLearnerSlot",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PICK_LEARNER_SLOT}`,
        {
          batchId: data.batchId,
          learnerId: data.learnerId,
          slotId: data.slotId,
        },
        {
          headers: {
            Authorization: `Token ${data.token}`,
          },
        }
      );

      const filteredData = res.data.data.filter((slot) => {
        return slot.id === +data.slotId;
      });

      return filteredData[0];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
