import moment from "moment";
import React, { useState } from "react";
import Loader from "../../assets/loadingSpinner.gif";
import { useEffect } from "react";
import { MdWatchLater, MdCalendarToday } from "react-icons/md";
import { TbUnlink } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAvailableSlotsFromEvent } from "redux-management";
import { DateTimePicker } from "./date-time-picker/DateTimePicker";
import { UserForm } from "./user-form/UserForm";
import { Button } from "antd";
import BookingConfirmedIcon from "../../assets/booking-confirmed-icon.png";

const initialUserDetails = {
  name: "",
  email: "",
  phone: null,
  organization: "",
};

const BookSlot = () => {
  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [date, setDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isSlotConfirm, setIsSlotConfirm] = useState(false);

  const dispatch = useDispatch();
  const { fetchingSlots, bookingSlot, event } = useSelector(
    (state) => state.bookSlot
  );

  const isEventExpired =
    new Date() > new Date(`${event?.expire_date} 23:59:59`);

  const { eventId } = useParams();

  useEffect(() => {
    dispatch(getAvailableSlotsFromEvent(eventId));
  }, [dispatch, eventId]);

  const handleSelect = (date) => {
    setDate(date);
    setSelectedSlot(null);
  };

  if (fetchingSlots === "loading") {
    return (
      <div className="flex justify-center">
        <img className="w-[25rem]" src={Loader} alt="" />
      </div>
    );
  } else if (fetchingSlots === "failed") {
    return <div>Error loading event data</div>;
  } else if (fetchingSlots === "success") {
    if (isEventExpired) {
      return (
        <section
          className={`sm:shadow-lg sm:mt-8 sm:border p-4 mx-auto rounded-lg max-w-[800px] flex flex-col gap-2 items-center justify center `}
        >
          <div className="text-center">
            <span className="text-lg font-semibold text-gray-500">Sorry!</span>
            <p className="flex gap-2 items-center">
              <TbUnlink /> Link that you followed is expired.
            </p>
          </div>
          <Button
            type="primary"
            className="bg-primary-ctc hover:bg-primary-ctc-hover rounded border-0"
          >
            <a href="mailto:info@meeraq.com?cc=anita@meeraq.com;preksha@meeraq.com">
              Contact Us
            </a>
          </Button>
        </section>
      );
    }

    if (bookingSlot === "success") {
      return (
        <div className="h-[100%]">
          <div class="bubbles">
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
          </div>
          <section
            className={`sm:shadow-lg sm:mt-8 sm:border pb-4 mx-auto rounded-lg max-w-[800px]`}
          >
            <div className="text-center py-2 border-b flex flex-col items-center justify-center">
              <img
                src={BookingConfirmedIcon}
                alt="celebration"
                className="h-[5rem] w-[5rem] "
              />
              <h1 className="pt-2 text-lg font-semibold">Confirmed</h1>
              <p>Your coaching session has been scheduled</p>
            </div>

            <div className="pt-2 pb-4 text-center w-fit mx-auto font-semibold text-gray-600 flex items-start ">
              <div className="flex items-center gap-2 py-2 text-slate-600">
                <MdWatchLater />
                30 minutes
              </div>
              <div className="flex items-start gap-2 ml-[4rem]">
                <span className="py-1">
                  <MdCalendarToday />
                </span>
                <p className="text-left">
                  {selectedSlot?.SESSION_START_TIME} -{" "}
                  {selectedSlot?.SESSION_END_TIME}
                  <div>{moment(date).format("dddd, Do MMMM YYYY")}</div>
                </p>
              </div>
            </div>
            <div className="border-t">
              <p className="py-2 text-center">
                A calendar invitation has been sent to your email address.{" "}
              </p>
            </div>
          </section>
        </div>
      );
    }

    if (bookingSlot === "failed") {
      return (
        <div className="flex flex-col items-center justify-center gap-2">
          <p>Failed to book the slot. Please refresh the page and try again</p>
          <Button
            type="primary"
            className="bg-primary-ctc rounded border-0"
            onClick={() => {
              dispatch(getAvailableSlotsFromEvent(eventId));
              setIsSlotConfirm(false);
              setUserDetails(initialUserDetails);
              setDate(null);
              setSelectedSlot(null);
            }}
          >
            Refresh.
          </Button>
        </div>
      );
    }

    if (bookingSlot === "loading") {
      return (
        <div className="flex justify-center">
          <img className="w-[25rem]" src={Loader} alt="" />
        </div>
      );
    }

    // if not scheduled yet
    return (
      <section
        className={`sm:shadow-lg sm:mt-8 flex sm:border mx-auto rounded-lg overflow-hidden flex-wrap lg:flex-nowrap ${
          date ? "max-w-[62.5rem]" : "max-w-[50rem]"
        }  ${isSlotConfirm && "md:w-[50%]"}`}
      >
        <div className="border-b lg:border-b-0 md:border-r w-[100%] lg:w-auto lg:grow p-2 sm:p-4">
          <h3 className="font-medium text-xl">Coaching Session</h3>
          <div className="flex items-center gap-2 text-lg py-2 text-slate-600">
            <MdWatchLater />
            30 minutes
          </div>
          {isSlotConfirm && (
            <div className="flex items-start gap-2 text-lg">
              <span className="py-1">
                <MdCalendarToday />
              </span>
              <p>
                {selectedSlot?.SESSION_START_TIME} -{" "}
                {selectedSlot?.SESSION_END_TIME},
                <div>{moment(date).format("dddd, Do MMMM YYYY")}</div>
              </p>
            </div>
          )}
        </div>
        {isSlotConfirm ? (
          <section className="md:w-[50%] p-4">
            <p className="text-lg font-semibold">Enter Details</p>
            <UserForm
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              selectedSlot={selectedSlot}
              setIsSlotConfirm={setIsSlotConfirm}
            />
          </section>
        ) : (
          <DateTimePicker
            date={date}
            handleSelect={handleSelect}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            setIsSlotConfirm={setIsSlotConfirm}
          />
        )}
      </section>
    );
  }
};

export { BookSlot };
