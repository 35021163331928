function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const getUniqueSlots = (slotsByDate, selectedDate) => {
  const uniqueStartTimes = [];
  const res = [];

  if (!slotsByDate[selectedDate] || slotsByDate[selectedDate].length === 0) {
    return [];
  }

  const arr = [...slotsByDate[selectedDate]];
  shuffleArray(arr);

  arr.sort((a, b) => {
    return +a.start_time - +b.start_time;
  });

  arr.forEach((slot) => {
    if (!uniqueStartTimes.includes(slot.start_time)) {
      uniqueStartTimes.push(slot.start_time);
      res.push(slot);
    }
  });

  return res;
};
