import { Header } from "components";
import { BookSlot, WelcomePage } from "pages";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div className="min-h-[100vh] flex flex-col">
      <Header />
      <main className="p-2 sm:p-4 grow">
        <Routes>
					<Route index element={<WelcomePage />} />
          <Route path="/book-slot/:eventId" element={<BookSlot />} />
          <Route
            path="*"
            element={<div className="text-center">404 Page not found.</div>}
          />
        </Routes>
      </main>
      <ToastContainer />
    </div>
  );
};

export default App;
